import React from "react";
import styles from "./Footer.module.scss"

const year = new Date().getFullYear()
const Footer = () => {
  return (
    <div className={styles.footer}>
      &copy; {year} All Rights Reserved
    </div>
  );
};

export default Footer;
